<template>
  <div class="relative">
    <div class="login-wrapper flex justify-center items-center" style="height:100vh;">
      <div class="rounded px-4 py-2 xl:w-1/5 lg:w-1/4 md:w-1/3 sm:w-2/3 w-full">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item>
          <p class="text-2xl text-blue-400 font-bold" style="text-shadow: 1px 1px #fff;">登录 - 信奥课堂</p>
          </el-form-item>
          <el-form-item v-if="phoneMode" prop="phone">
            <el-input type="text" maxlength="11" prefix-icon="el-icon-mobile-phone" placeholder="手机号" v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item v-if="phoneMode" prop="code">
            <div class="flex">
              <el-input class="mr-2" type="text" maxlength="6" prefix-icon="el-icon-lock" placeholder="验证码" v-model="form.code"/>
              <el-button :disabled="disabled" @click="getCode">{{ valiBtnText }}</el-button>
            </div>
          </el-form-item>
          <el-form-item v-if="!phoneMode" prop="username">
            <el-input prefix-icon="el-icon-user" v-model="form.username" placeholder="账号"></el-input>
          </el-form-item>
          <el-form-item v-if="!phoneMode" prop="password">
            <el-input prefix-icon="el-icon-lock" show-password v-model="form.password" placeholder="密码"></el-input>
          </el-form-item>
          <el-form-item class="mb-1">
            <div v-if="slideVerifyVisiale" class="absolute p-2 bg-white shadow " style="z-index:10;">
              <slide-verify ref="slideblock" :imgs="['https://haimakid-cdn.haimakid.net/web/slide-verify.png']" slider-text="向右滑动" @success="slideSuccessLogin"></slide-verify>
            </div>
            <el-button class="w-full" type="primary" :loading="isloading" @click="readyPostLogin">登录</el-button>
          </el-form-item>
          <el-form-item>
            <div v-if="!phoneMode" class="flex justify-between">
              <el-button type="text" @click="checkMode">手机登录</el-button>
              <!-- <el-button type="text">忘记密码？</el-button> -->
            </div>
            <div v-else class="flex">
              <el-button type="text" @click="checkMode">密码登录</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>

import { phoneCaptcha } from '@/api/user'
import md5 from 'md5'

export default {
  name: 'Login',
  components: {
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/
      if ( reg.test(value) ) {
        callback()
      } else {
        callback(new Error('请输入合法的手机号'))
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入6位验证码'))
      } else {
        callback()
      }
    }
    const validateUsername = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('请输入用户名'))
      } else if (value.trim().length < 4) {
        callback(new Error('用户名不能小于4个字符'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('密码不能小于6个字符'))
      } else {
        callback()
      }
    }
    return {
      form: {
        username: '',
        password: '',
        phone: '',
        code: '',
      },
      slideVerifyVisiale: false,
      isloading: false,
      rules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername }
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ],
        phone: [
          { required: true, trigger: 'blur', validator: validatePhone }
        ],
        code: [
          { required: true, trigger: 'blur', validator: validateCode }
        ]
      },
      redirect: undefined,
      phoneMode: false,
      valiBtnText: '获取验证码',
      disabled: false
    }
  },
  mounted() {
    this.redirect = this.$route.query.redirect
  },
  methods: {
    readyPostLogin() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.slideVerifyVisiale = true
        }
      })
    },
    slideSuccessLogin() {
      this.isloading = true
      setTimeout(() => {
        if (this.phoneMode) {
          const { phone, code } = this.form
          this.$store.dispatch('user/localPhoneLogin', { phone, code })
              .then(() => {
                this.$router.push({ path: this.redirect || '/' })
                this.isloading = false
              })
              .catch(() => {
                this.isloading = false
                this.slideVerifyVisiale = false
                this.$refs.slideblock.reset()
              })
        } else {
          const { username, password } = this.form
          this.$store.dispatch('user/localLogin', { username, password: md5(md5(password)) })
              .then(() => {
                this.$router.push({ path: this.redirect || '/' })
                this.isloading = false
              })
              .catch(() => {
                this.isloading = false
                this.slideVerifyVisiale = false
                this.$refs.slideblock.reset()
              })
        }
      }, 1000)
      
    },
    checkMode() {
      this.phoneMode = !this.phoneMode
      this.$refs['form'].resetFields()
    },
    // 获取验证码
    getCode() {
      this.$refs['form'].validateField('phone', (err) => {
        if (err) {
          return false
        } else {
          this.codeCountDown()
          phoneCaptcha({ phone: this.form.phone }).then(() => {
            this.$message({
              type: 'success',
              message: '发送成功'
            })
          })
        }
      })
    },
    // 验证码倒数
    codeCountDown() {
      let time = 60
      let timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.valiBtnText = '获取验证码'
          this.disabled = false
        } else {
          this.disabled = true
          this.valiBtnText = `${time} 秒后重试`
          time--
        }
      }, 1000)
    }
  }
}
</script>